exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-formationen-tsx": () => import("./../../../src/pages/formationen.tsx" /* webpackChunkName: "component---src-pages-formationen-tsx" */),
  "component---src-pages-fotos-tsx": () => import("./../../../src/pages/fotos.tsx" /* webpackChunkName: "component---src-pages-fotos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-musik-tsx": () => import("./../../../src/pages/musik.tsx" /* webpackChunkName: "component---src-pages-musik-tsx" */),
  "component---src-pages-referenzen-tsx": () => import("./../../../src/pages/referenzen.tsx" /* webpackChunkName: "component---src-pages-referenzen-tsx" */)
}

